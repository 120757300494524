
// Custom validations for bootstrap validator
function customValidations(){
    var validatorOptions = {
        delay: 100,
        custom: {
            cp: function ($el) {
                return validarCP($el.val());
            },
            telefono: function ($el) {
                if ($el.val() != ''){
                  return validarMovil($el.val()) || validarFijo($el.val());
                }else{
                  return true;
                }  
            },
            dni: function ($el) {
                return validarDNI($el.val().toUpperCase());
            },
            dnibr: function($el){
              return validarDNIBR($el.val());
            },
            mayoredad: function ($el) {
                edad = $el.val().split('/');
                return validarMayorEdad(edad[0], edad[1], edad[2]);
            },
            fecha: function ($el) {
                _fecha = $el.val().split('/');
                return esFechaValida(_fecha[0]+'-'+_fecha[1]+'-'+_fecha[2]); //dd/mm/aaaa
            },
            hora: function ($el) {
                //_fecha = $el.val().split('/');
                return esHora($el.val()); //hh/mm
            },
            mayorhoy: function($el){
                _fecha = $el.val().split('/');
                return validarMayorHoy(_fecha[0], _fecha[1], _fecha[2]);
            },
            fechalimite: function($el){
                _fecha = $el.val().split('/');
                return validarFechaLimite(_fecha[0], _fecha[1], _fecha[2]);
            },
            file: function ($el) {
                if ($el.val() ==''){
                  $($el).parents('.form-group').addClass('has-error');
                  $($el).parents('.form-group').removeClass('has-sucess');
                 // $('#nombreImagen').trigger('change');
                  return false;
                }else{
                  $($el).parents('.form-group').removeClass('has-error');
                  $($el).parents('.form-group').addClass('has-sucess');
                 // $('#nombreImagen').trigger('change');
                  return true;
                }
            },
            cpf: function($el){
                return validarCPF($el.val());
            },
            contrasena: function($el){
                return validarContrasena($el.val());
            }
        },
        errors: {
            cp: "Revisa el código postal",
            telefono: "Revisa el teléfono",
            dni: "Revisa el DNI",
            mayoredad: "Para participar en la promoción tienes que ser mayor de edad",
            fecha: "Introduce una fecha válida",
            hora: "Introduce una hora válida en el formato indicado",
            file: "Selecciona un archivo",
            mayorhoy: "Selecciona una fecha posterior al día de hoy",
            fechalimite: "Selecciona una fecha anterior al 07/04/2018",
            cpf: "Revisa el código postal",
            dnibr: "Revisa el dni",
            contrasena: "Contraseña incorrecta"
        },
        
    }

    return validatorOptions;

}

function esHora(_hora){// formato hh:mm

  hora = _hora.split(':');
  
  if (parseInt(hora[0]) < 0 || parseInt(hora[0])>24){
    return false;
  }
  if (parseInt(hora[1]) < 0 || parseInt(hora[1])>59){
    return false;
  }
  if (hora.length > 1){
    if (hora[1].length < 2){ //para no permitir algo del tipo 12:1
      return false;
    }
  }
  else {
      return false;
  }
  return true;

}

function validarMayorHoy_OLD(_dia, _mes, _anno){

    day = _dia;
    month = _mes;
    year = _anno;
    //console.log(day+'-'+month+'-'+year);
    var mydate = new Date(year, month, day);
    var hoy = new Date();
//console.log(mydate.getTime()+'::'+hoy.getTime());
   
    if (hoy.getTime() < mydate.getTime()) {
        return true;
    }
    return false;
}
function validarMayorHoy(_dia, _mes, _anno){
        day = _dia;
        month = _mes;
        year = _anno;
        //console.log(day+'-'+month+'-'+year);
        var date2 = new Date(year, month-1, day);
        //date2.setFullYear(year, month - 1, day);
        
        var hoy = new Date();
        dia = hoy.getDate();
        mes = hoy.getMonth();
        anio= hoy.getFullYear();
        
        var today = new Date(anio, mes, dia);

        //var today = new Date();
        
        
        if (date2<today)
        {   
            return false;
        }
          else
          {
              return true;
          }   
}
function validarMayorEdad(_dia, _mes, _anno){

    day = _dia;
    month = _mes;
    year = _anno;
    //console.log(day+'-'+month+'-'+year);
    var mydate = new Date();
    mydate.setFullYear(year, month - 1, day);

    var maxDate = new Date();
    maxDate.setYear(maxDate.getYear() - 18);
    //console.log(maxDate);
    //console.log(mydate);
    if (maxDate < mydate) {
        return false;
    }
    return true;
}
function validarFechaLimite(_dia, _mes, _anno){
    day = _dia;
    month = _mes;
    year = _anno;
        

    var date2 = new Date(year, month-1, day);
        //date2.setFullYear(year, month - 1, day);
    var flimite = new Date('2018', '3', '7');
        //console.log(date2);
        
    if (date2>flimite)
      {   
        return false;
      }
    else
     {
        return true;
      }   
}
function validarCPF(cpf) {
//  var exr = /^\d{3}\.\d{3}\.\d{3}\-\d{2}/i;
  var exr = /\d{11}/;

  return exr.test(cpf);
}
function validarEmail(mail) {
  var exr = /^[0-9a-z_\-\.]+@[0-9a-z\-\.]+\.[a-z]{2,4}$/i;
  return exr.test(mail);
}
function validarMovil(tlf) {
  var exr = /[6|7]\d{8}/;
  return exr.test(tlf);
}
function validarFijo(tlf) {
  var exr = /9\d{8}/;
  return exr.test(tlf);
}
function validarContrasena(pass){
  var exr = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return exr.test(pass);
}
function validarDNI2(_dni){
  _dni = _dni.toUpperCase();
  var exr =  "(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]{1}))";
  return exr.test(_dni);
}

function validarDNIBR(_dni){
  var exr = /\d{11}/;
  return exr.test(_dni);
}

function isDigit (c){
     return ((c >= "0") && (c <= "9"))
}

function letra_nif(dni){
    var numero = dni % 23 + 1;
    letra='TRWAGMYFPDXBNJZSQVHLCKET';
    letra=letra.substring(numero-1,numero);
    return letra;
}    
    
    
function validar_nie(valor,numero_digitos){
     var i;
     var dni=valor;
     primera_letra = dni.charAt(0);
     if(primera_letra.toUpperCase()!="X" && primera_letra.toUpperCase()!="T" && primera_letra.toUpperCase()!="Y")
          return false;
         
     for (i = 1; i < numero_digitos; i++)
    {  
        var c = dni.charAt(i);
        if (!isDigit(c))
        {
            return false;
        }
    }
    var letra_introducido = letra_nif(dni.substring(1,numero_digitos+1));
    var letra_real = dni.charAt(numero_digitos+1);
    if (letra_introducido == letra_real.toUpperCase())
    {
        return true;
    }
    else
    {
        return false;
    }        
}

function validar_nif(valor){
     var i;
     var dni=valor;
    
     if(dni.length<4){
          return false;    
     }
    
     for (i = 0; i < dni.length-1; i++)
    {  
        var c = dni.charAt(i);
        if (!isDigit(c))
        {
            return false;
        }
    }
    var letra_introducido = letra_nif(dni.substring(0,dni.length-1));
    var letra_real = dni.charAt(dni.length-1);
    if (letra_introducido == letra_real.toUpperCase())
    {
        return true;
    }
    else
    {
        return false;
    }
}    
    

function validarDNI(valor) {

    if(valor.length<9) {
      return false;
    }
   
    dni = valor.toUpperCase();

    letra = valor.substring(valor.length - 1);
    numero = valor.substring(0, 8);

    if (numero.indexOf("X") >= 0){
      numero = numero.replace("X", 0);
    }
    if (numero.indexOf("Y") >= 0){
      numero = numero.replace("Y", 1);
    }
    if (numero.indexOf("Z") >= 0){
      numero = numero.replace("Z", 2);
    }
   
    // Si es un NIE hay que cambiar la primera letra por 0, 1 ó 2 dependiendo de si es X, Y o Z.
   
    modulo = numero % 23;
    letras_validas = "TRWAGMYFPDXBNJZSQVHLCKE";
    letra_correcta = letras_validas.substring(modulo, modulo+1);   //substr($letras_validas, $modulo, 1);
    //console.log('modulo = '+modulo);
    //console.log('letra = '+letra+', letra valida = '+letra_correcta+', numero = '+numero);
    if(letra_correcta!=letra) {
      return false;
    }else {
      return true;
    }


  //var exr = /6\d{8}/;
  //return exr.test(dni);
  //return ( validar_nif(valor) || validar_nie(valor,7) || validar_nie(valor,8) );
}


function contadorTextArea (campo,limite) {
  if (campo.value.length > limite) campo.value = campo.value.substring(0, limite);
  
}

function validaFechaDDMMAAAA(fecha){
  var dtCh= "-";
  var minYear=1900;
  var maxYear=2100;
  function isInteger(s){
    var i;
    for (i = 0; i < s.length; i++){
      var c = s.charAt(i);
      if (((c < "0") || (c > "9"))) return false;
    }
    return true;
  }
  function stripCharsInBag(s, bag){
    var i;
    var returnString = "";
    for (i = 0; i < s.length; i++){
      var c = s.charAt(i);
      if (bag.indexOf(c) == -1) returnString += c;
    }
    return returnString;
  }
  function daysInFebruary (year){
    return (((year % 4 == 0) && ( (!(year % 100 == 0)) || (year % 400 == 0))) ? 29 : 28 );
  }
  function DaysArray(n) {
    for (var i = 1; i <= n; i++) {
      this[i] = 31
      if (i==4 || i==6 || i==9 || i==11) {this[i] = 30}
      if (i==2) {this[i] = 29}
    }
    return this
  }
  function isDate(dtStr){
    var daysInMonth = DaysArray(12)
    var pos1=dtStr.indexOf(dtCh)
    var pos2=dtStr.indexOf(dtCh,pos1+1)
    var strDay=dtStr.substring(0,pos1)
    var strMonth=dtStr.substring(pos1+1,pos2)
    var strYear=dtStr.substring(pos2+1)
    strYr=strYear
    if (strDay.charAt(0)=="0" && strDay.length>1) strDay=strDay.substring(1)
    if (strMonth.charAt(0)=="0" && strMonth.length>1) strMonth=strMonth.substring(1)
    for (var i = 1; i <= 3; i++) {
      if (strYr.charAt(0)=="0" && strYr.length>1) strYr=strYr.substring(1)
    }
    month=parseInt(strMonth)
    day=parseInt(strDay)
    year=parseInt(strYr)
    if (pos1==-1 || pos2==-1){
      return false
    }
    if (strMonth.length<1 || month<1 || month>12){
      return false
    }
    if (strDay.length<1 || day<1 || day>31 || (month==2 && day>daysInFebruary(year)) || day > daysInMonth[month]){
      return false
    }
    if (strYear.length != 4 || year==0 || year<minYear || year>maxYear){
      return false
    }
    if (dtStr.indexOf(dtCh,pos2+1)!=-1 || isInteger(stripCharsInBag(dtStr, dtCh))==false){
      return false
    }
    return true
  }
  if(isDate(fecha)){
    return true;
  }else{
    return false;
  }
}

function esFechaValida(fecha){
  if (fecha != undefined && fecha != "" ){
    if (!/^\d{2}\-\d{2}\-\d{4}$/.test(fecha)){
//      alert("formato de fecha no vÃ¡lido (dd/mm/aaaa)");
      return false;
    }
    var dia  =  parseInt(fecha.substring(0,2),10);
    var mes  =  parseInt(fecha.substring(3,5),10);
    var anio =  parseInt(fecha.substring(6),10);
  switch(mes){
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      numDias=31;
      break;
    case 4: case 6: case 9: case 11:
      numDias=30;
      break;
    case 2:
      if (comprobarSiBisisesto(anio)){ numDias=29 }else{ numDias=28};
      break;
    default:
    //  alert("Fecha introducida errÃ³nea");
      return false;
  }
    if (dia>numDias || dia==0){
  //    alert("Fecha introducida errÃ³nea");
      return false;
    }
    return true;
  }
}
function comprobarSiBisisesto(anio){
if ( ( anio % 100 != 0) && ((anio % 4 == 0) || (anio % 400 == 0))) {
  return true;
  }
else {
  return false;
  }
}

function validarCP(_cp){
    
    var exr = /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/;
    return exr.test(_cp);

}
function validarProvinciaCP(_provincia, _cp){

  return false;
}

// Custom messages for own browser validations ****** ****** ****** ****** ****** ******
(function init_custom_validations(){
  var x = document.getElementsByClassName("input-nombre");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su nombre.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-apellido1");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su primer apellido.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-apellido2");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su segundo apellido.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-email");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su dirección de email.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        case this.validity.typeMismatch:
          this.setCustomValidity('Revise su dirección de email.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-telefono");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su número de teléfono.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-movil");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su número de teléfono móvil.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
})();

(function($) {

  $.fn.simpleAjaxForm = function(options) {
        var defaults = {
            url: ($(this).prop("tagName")=='FORM')?$(this).attr('action'):$(this).data('url'),
            ajaxTarget:($(this).data('ajax-target'))?$(this).data('ajax-target'):'',
            data: ($(this).prop('tagName')=='FORM') ? $(this).serialize() : $(this).closest('form').serialize(),
            spinTarget: $(this).data('id-spin-target'),
            mensajeError: "Se ha producido un error al procesar su peticion",
            strType:($(this).prop("tagName")=='FORM')?$(this).attr('method'):(($(this).data('srttype'))?$(this).data('srttype'):'GET'),
            callback: ($(this).data('callback'))?$(this).data('callback'):function(obj,target, result){}
        }

        var settings = $.extend( {}, defaults, options );
        if($(this).data('strtype')){
            settings.strType=$(this).data('strtype');
        }
        var url=settings.url,
            spinTarget=settings.spinTarget,
            ajaxTarget=settings.ajaxTarget,
            data=$(this).data('ajax-no-data') ? {} : settings.data,
            strType=settings.strType,
            callback=settings.callback,
            obj=($(this).prop("tagName")=='FORM')?$(this):$(this).closest('form');

           
        dataType = (ajaxTarget == '')?'json':'html';

        spinner_holder = $(this).parents('.inner-contenedor-formulario').find('.js-spinner-holder');
//console.log('data::::'+data);
        spinner_holder.fadeIn(function(){
            //perform ajax request
            $.ajax({
                url: url,
                data: data,
                dataType: dataType,
                type:strType
            })
            .done( function (result) {
                if (dataType == 'html'){
                    $(ajaxTarget).html(result);                    
                }
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, spinner_holder)}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
                //spinner_holder.fadeOut(); // el spinner lo oculta el callback
                
            })
            .fail(function() {
                result = '{"resultado":"KO","error":{"code":"90102","msg_error":"Ha ocurrido un error, vuelva a intentarlo más tarde."}}';
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, spinner_holder)}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
                //spinner_holder.fadeOut(); // el spinner lo oculta el callback
            });

        });

        


        //loader_spinner(spinTarget,'hide'); // Lo debe cerrar el callback
    };
//HAY QUE PARAMETRIZARLA!!!!!!!!!
  $.fn.simpleAjaxFormFiles = function(options) {
        var defaults = {
            url: ($(this).prop("tagName")=='FORM')?$(this).attr('action'):$(this).data('url'),
            ajaxTarget:($(this).data('ajax-target'))?$(this).data('ajax-target'):'',
            data: ($(this).prop('tagName')=='FORM') ? $(this).serialize() : $(this).closest('form').serialize(),
            spinTarget: $(this).data('id-spin-target'),
            mensajeError: "Se ha producido un error al procesar su peticion",
            strType:($(this).prop("tagName")=='FORM')?$(this).attr('method'):(($(this).data('srttype'))?$(this).data('srttype'):'GET'),
            callback: ($(this).data('callback'))?$(this).data('callback'):function(obj,target, result){}
        }
        var settings = $.extend( {}, defaults, options );
        if($(this).data('strtype')){
            settings.strType=$(this).data('strtype');
        }
        var url=settings.url,
            spinTarget=settings.spinTarget,
            ajaxTarget=settings.ajaxTarget,
            data=$(this).data('ajax-no-data') ? {} : settings.data,
            strType=settings.strType,
            callback=settings.callback,
            obj=($(this).prop("tagName")=='FORM')?$(this):$(this).closest('form');


       // var form = $(this);
        var titulo = $("#txttitulo").val();
 
        var idparticipantes = $("#idparticipantes").val();

        var txttipo = $("#txttipo").val();

        var file2 = $('#ficfoto');  

        var archivo = file2[0].files;      

         // Crea un formData y lo envías
       // var formData = new FormData('form'); --ESTE FUNCIONA
        var formData = new FormData($('#fSubirFotos').get(0));
        
        
        /*formData.append('txttitulo',titulo);

        formData.append('idparticipantes',idparticipantes);

        formData.append('txttipo',txttipo);*/

        formData.append('files[]',archivo);     


        dataType = 'html';
        //spinner_holder = $(this).parents('.inner-contenedor-formulario').find('.js-spinner-holder');
        
        //spinner_holder.fadeIn(function(){
            //perform ajax request
            $.ajax({
                url: url,
                type: "post",
                data: formData,
                dataType: "html",
                cache: false,
                contentType: false,
                processData: false
            })
            .done( function (result) {
                if (dataType == 'html'){
                    $(ajaxTarget).html(result);                    
                }
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, spinner_holder)}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
                ////spinner_holder.fadeOut(); // el spinner lo oculta el callback
                
            })
            .fail(function() {
                result = '{"resultado":"KO","error":{"code":"90102","msg_error":"Ha ocurrido un error, vuelva a intentarlo más tarde."}}';
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, spinner_holder)}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
               // //spinner_holder.fadeOut(); // el spinner lo oculta el callback
            });

       // });

        


        //loader_spinner(spinTarget,'hide'); // Lo debe cerrar el callback
    };


})(jQuery);

